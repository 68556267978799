// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, serverTimestamp } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8rftJVXbdftVOrjyBwLXAxlUIOxbvLEE",
  authDomain: "vreact-1c052.firebaseapp.com",
  databaseURL: "https://vreact-1c052-default-rtdb.firebaseio.com",
  projectId: "vreact-1c052",
  storageBucket: "vreact-1c052.appspot.com",
  messagingSenderId: "239143194004",
  appId: "1:239143194004:web:4d1e6241fcc7b73966d381",
  measurementId: "G-EYK7NYHB3Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);

export { firestore, serverTimestamp };