import React, { useEffect } from 'react';

const initializeOneSignal = () => {
  if (window.OneSignal) {
    window.OneSignal = window.OneSignal || [];
    window.OneSignal.push(function() {
      window.OneSignal.init({
        appId: '59c28ce6-8c41-42ee-a065-7e538be4275b',
      });
    });
  } else {
    console.error('OneSignal SDK not loaded correctly.');
  }
};

const OneSignalInitializer = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
    script.async = true;
    script.onload = () => {
      initializeOneSignal();
    };
    document.head.appendChild(script);
  }, []);

  return null;
};

export default OneSignalInitializer;
