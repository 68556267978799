import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, List, ListItem, ListItemText, Typography, Card, CardContent } from '@mui/material';
import { firestore, serverTimestamp } from '../firebaseConfig';
import { collection, addDoc, query, orderBy, onSnapshot } from 'firebase/firestore';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const q = query(collection(firestore, 'chatMessages'), orderBy('timestamp', 'asc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMessages(data);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (input.trim() === '') return;

    await addDoc(collection(firestore, 'chatMessages'), {
      text: input,
      timestamp: serverTimestamp()
    });

    setInput('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Container style={{ marginTop: '20px', marginBottom: '80px' }}>
      <Typography variant="h4" gutterBottom>
        Chat
      </Typography>
      <Card style={{ maxHeight: '60vh', overflow: 'auto', padding: '20px' }}>
        <CardContent>
          <List>
            {messages.map((msg) => (
              <ListItem key={msg.id} alignItems="flex-start">
                <ListItemText
                  primary={msg.text}
                  secondary={msg.timestamp ? msg.timestamp.toDate().toLocaleString() : 'Sending...'}
                />
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
        </CardContent>
      </Card>
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <TextField
          fullWidth
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
          onKeyPress={handleKeyPress}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          style={{ marginLeft: '10px' }}
        >
          Send
        </Button>
      </div>
    </Container>
  );
};

export default Chat;
