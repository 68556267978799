import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ChatIcon from '@mui/icons-material/Chat';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

const BottomNavbar = () => {
  return (
    <BottomNavigation showLabels style={{ position: 'fixed', bottom: 0, width: '100%' }}>
      <BottomNavigationAction label="Home" icon={<HomeIcon />} href="/" />
      <BottomNavigationAction label="Timer" icon={<AccessAlarmsIcon />} href="exhibition" />
      <BottomNavigationAction label="Enjoy" icon={<LocalActivityIcon />} href="/game" />
      <BottomNavigationAction label="Chat" icon={<ChatIcon />} href="/chat" />
    </BottomNavigation>
  );
};

export default BottomNavbar;
