import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, CardMedia, CardActionArea, Button, Box } from '@mui/material';
import Spacer from '../components/Spacer';
import TwitterTimeline from '../components/TwitterTimeline';
import Omikuji from '../components/Omikuji';

import web2024 from './images/Web 2024.png';
import portal from './images/Web Portal.png';

const Home = () => {
  const sites = [
    {
      title: "Vconf Portal",
      description: "ポータルサイト",
      link: "https://vconf.org/portal/",
      image: portal
    },
    {
      title: "Vconf 2024",
      description: "2024 大会サイト",
      link: "https://vconf.org/2024/",
      image: web2024
    }
  ];

  const sitesOther = [
    {
      title: "Vconf 2023",
      description: "2023年 大会サイト",
      link: "http://vconf.org/2023/"
    },
    {
        title: "Vconf 2022",
        description: "2022年 大会サイト",
        link: "http://vconf.org/2022/"
    },
    {
        title: "Vconf 2021",
        description: "2021年 大会サイト",
        link: "http://vconf.org/2021/"
    },
    {
        title: "Vconf 2020",
        description: "2020年 大会サイト",
        link: "http://vconf.org/2020/"
    },
    {
        title: "Vconf 2019",
        description: "2019年 大会サイト",
        link: "http://vconf.org/2019/"
    }
  ];

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        バーチャル学会
      </Typography>
      <Typography variant="body1" gutterBottom>
        Web関連の情報をまとめる用として、なんとなく作ったものです。
      </Typography>
      <Spacer height={"30px"}/>
      <Typography variant="h6" gutterBottom>
        公式サイトリンク
      </Typography>
      <Grid container spacing={3}>
        {sites.map((site, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card style={{ backgroundColor: '#f0f0f0' }}>
              <CardActionArea href={site.link} target="_blank" rel="noopener">
                <Box p={1}>
                  <CardMedia
                    component="img"
                    width="100%"
                    image={site.image}
                    alt={site.title}
                    style={{ objectFit: 'contain', borderRadius: '4px' }}
                  />
                </Box>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {site.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {site.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Spacer height={"50px"}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Card style={{ backgroundColor: '#f0f0f0' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                過去サイト
              </Typography>
              <Grid container spacing={3}>
                {sitesOther.map((site, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card style={{ backgroundColor: '#2fd4c0' }}>
                      <CardActionArea href={site.link} target="_blank" rel="noopener">
                        <CardContent>
                          <Typography variant="h5" component="div">
                            {site.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {site.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={{ backgroundColor: '#f0f0f0' }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Official Twitter
              </Typography>
              <Box display="flex" justifyContent="center" p={2}>
                <TwitterTimeline profile="Vsociety_office" />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '10px' }}>
        <Grid item xs={12} sm={12}>
          <Omikuji />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
