import React, { useEffect } from 'react';

const TwitterTimeline = ({ profile }) => {
  useEffect(() => {
    // Twitterの埋め込みスクリプトを追加
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // クリーンアップ
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <a
        className="twitter-timeline"
        data-theme="light"
        data-width="300"
        data-height="400"
        data-chrome="noheader nofooter noborders"
        href={`https://twitter.com/${profile}`}
      >
        Tweets by @{profile}
      </a>
    </div>
  );
};

export default TwitterTimeline;
