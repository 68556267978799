import React, { useState } from 'react';
import { Container, Typography, Card, CardContent, Button, Box } from '@mui/material';

const fortunes = [
  "大吉",
  "中吉",
  "小吉",
  "末吉",
  "吉",
  "中吉",
  "小吉",
  "末吉",
  "吉",
  "小吉",
  "末吉",
  "吉",
  "凶"
];

const Omikuji = () => {
  const [fortune, setFortune] = useState(null);

  const drawFortune = () => {
    const randomFortune = fortunes[Math.floor(Math.random() * fortunes.length)];
    setFortune(randomFortune);
  };

  return (
    <Container style={{ marginTop: '10px' }}>
      <Card style={{ backgroundColor: '#f0f0f0' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            おみくじ
          </Typography>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100px">
            {fortune ? (
              <>
                <Typography variant="h4" component="div">
                  {fortune}
                </Typography>
                <Button variant="contained" color="primary" onClick={drawFortune} style={{ marginTop: '10px' }}>
                  もう一度引く
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1" component="div">
                  おみくじを引いて運勢を占おう！
                </Typography>
                <Button variant="contained" color="primary" onClick={drawFortune} style={{ marginTop: '10px' }}>
                  おみくじを引く
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Omikuji;
