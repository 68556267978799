import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

const TopNavbar = () => {
  return (
    <AppBar position="fixed" style={{ top: 0 }}>
      <Toolbar>
        <Typography variant="h6">
          Virtual Conference
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavbar;
