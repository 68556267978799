import React from 'react';
import { useParams } from 'react-router-dom';

// Sample data
const sessionDetails = {
  1: { title: 'Opening Keynote', speaker: 'Dr. Smith', description: 'Welcome to the conference...' },
  2: { title: 'React Workshop', speaker: 'John Doe', description: 'An in-depth look at React...' },
  // Add more session details as needed
};

const SessionDetail = () => {
  const { id } = useParams();
  const session = sessionDetails[id];

  return (
    <div className="session-detail">
      <h2>{session.title}</h2>
      <p><strong>Speaker:</strong> {session.speaker}</p>
      <p>{session.description}</p>
    </div>
  );
};

export default SessionDetail;
