import React, {useEffect, useState} from 'react';
import OneSignalInitializer from './OneSignalInitializer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';

import TopNavbar from './components/TopNavbar';
import BottomNavbar from './components/BottomNavbar';

import Home from './pages/Home';
import SessionDetail from './pages/SessionDetail';
import Exhibition from './pages/Exhibition';
import Chat from './pages/Chat';
import Game from './pages/Game';

const App = () => {
  return (
    <Router>
      <OneSignalInitializer />
      <div className="App">
        <TopNavbar />
        <Container style={{ marginTop: '80px', marginBottom: '80px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/session/:id" element={<SessionDetail />} />
            <Route path="/exhibition" element={<Exhibition/>} />
            <Route path="/game" element={<Game />} />
            <Route path="/chat" element={<Chat />} />
          </Routes>
        </Container>
        <BottomNavbar />
      </div>
    </Router>
  );
}

export default App;
