import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Button, Box, Link } from '@mui/material';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const Exhibition = () => {
  const [nextBreak, setNextBreak] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [totalDuration, setTotalDuration] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const audioRef = useRef(new Audio('https://vconf-for-react.yashubustudioetc.com/audio/pan.mp3')); // 外部URLを指定

  useEffect(() => {
    scheduleNextBreak();
  }, []);

  useEffect(() => {
    if (nextBreak && !isPaused) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = nextBreak - now;

        if (distance < 0) {
          clearInterval(interval);
          audioRef.current.play().catch(error => {
            console.error('Autoplay was prevented:', error);
          });
          setIsEnded(true);
          scheduleNextBreak();
        } else {
          setTimeLeft(distance);
          const elapsed = totalDuration - distance;
          setPercentage((elapsed / totalDuration) * 100);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [nextBreak, isPaused]);

  const scheduleNextBreak = () => {
    const baseTime = 90 * 60 * 1000; // 90分
    const randomOffset = Math.floor(Math.random() * 60 * 60 * 1000) - 30 * 60 * 1000; // ±30分
    const nextTime = new Date().getTime() + baseTime + randomOffset;
    setNextBreak(nextTime);
    setTotalDuration(baseTime + randomOffset);
    setIsEnded(false);
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours > 0 ? `${hours}h ` : ''}${minutes}m ${seconds}s`;
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const resumeTimer = () => {
    setIsPaused(false);
  };

  const resetTimer = () => {
    setNextBreak(null);
    setTimeLeft(null);
    setPercentage(0);
    setIsEnded(false);
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
  };

  const endNow = () => {
    setTimeLeft(0);
    setPercentage(100);
    audioRef.current.play().catch(error => {
      console.error('Autoplay was prevented:', error);
    });
    setIsEnded(true);
  };

  const stopAudio = () => {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsEnded(false);
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Working Timer
      </Typography>
      <Typography variant="body1" gutterBottom>
        次の休憩時間までの時間を表示します。(設定時間：90分±30分)
      </Typography>
      <Box height={"30px"} />
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Box style={{ width: 200, height: 200, marginBottom: '20px', position: 'relative' }}>
          <CircularProgressbar
            value={percentage}
            text={timeLeft !== null ? formatTime(timeLeft) : ''}
            styles={buildStyles({
              textSize: '16px',
              pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
              textColor: '#3e98c7',
              trailColor: '#d6d6d6',
              backgroundColor: '#3e98c7',
            })}
          />
          {isEnded && (
            <Button
              variant="contained"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              onClick={stopAudio}
            >
              停止
            </Button>
          )}
        </Box>
        <Box display="flex" justifyContent="center" width="100%" mb={2}>
          <Button
            variant="contained"
            style={{
              margin: '10px',
              width: '75%',
              maxWidth: '300px', // 最大幅を300pxに設定
              height: '60px', // 通常のボタンの高さの1.5倍
            }}
            onClick={scheduleNextBreak}
          >
            リスタート
          </Button>
        </Box>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            style={{
              margin: '10px',
              width: '35%',
              maxWidth: '140px', // 各ボタンの最大幅を設定
              height:'50px',
            }}
            onClick={isPaused ? resumeTimer : pauseTimer}
          >
            {isPaused ? '再開' : '一時停止'}
          </Button>
          <Button
            variant="contained"
            style={{
              margin: '10px',
              width: '35%',
              maxWidth: '140px', // 各ボタンの最大幅を設定
              height:'50px',
            }}
            onClick={endNow}
          >
            即時終了
          </Button>
        </Box>
      </Box>
      <Typography variant="body1" gutterBottom style={{ color: 'gray' }}>
          使用楽曲：<Link href="https://dova-s.jp/bgm/play19136.html" target="_blank" rel="noopener" style={{ color: 'gray' }}>今日のおやつはパンケーキ</Link><br></br>
          前回の会場BGMとか使いたいなー|ω･)ﾁﾗ
        </Typography>
    </Container>
  );
};

export default Exhibition;
