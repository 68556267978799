import React, { useState } from 'react';
import { Container, Typography, Button, Box, Paper } from '@mui/material';
import Spacer from '../components/Spacer';

const Game = () => {
  const [score, setScore] = useState(0);

  const handleClick = () => {
    setScore(score + 1);
  };

  return (
    <Container style={{ marginTop: '40px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        クリックゲーム
      </Typography>
      <Typography variant="body1">
        ボタンをクリックしてポイントを貯めよう！
      </Typography>
      <Spacer height={'30px'}/>
      <Paper elevation={3} style={{ padding: '40px', display: 'inline-block' }}>
        <Typography variant="h4" gutterBottom>
          スコア: {score}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{ width: '200px', height: '80px', fontSize: '1.5rem' }}
        >
          クリック！
        </Button>
      </Paper>
    </Container>
  );
};

export default Game;
